@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Sora', sans-serif;
  box-sizing:border-box;
  margin:0;
  padding:0;
}

.typed-text{
    font-weight: normal;
    color:#017c89;
    margin-left: 14px;
}

.image-logo-div{
    margin: 2.5em auto;
    padding:0;
}
.image-logo{
    display:flex;
    justify-content:center;
    position:relative;
    margin-right:auto;
    margin-left:auto; 
    width: 400px;
}

@media screen and (max-width:780px){
    .image-logo{
        width: 300px;
    }
}
@media screen and (max-width:400px){
    .image-logo{
        width: 100%;
    }
}

